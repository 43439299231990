import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BackgroundImage from '../components/BackgroundImage';
import Image from '../components/Image';
import { ImageOverlay } from '../utils';
import { HTMLContent } from '../components/Content';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const ServiceOptionsPageTemplate = ({
  image,
  title,
  helmet,
  blocks,
  content,
}) => {
  return (
    <div>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='container content'>
        <div className='columns'>
          <div className='column is-1' />
          <div className='column is-5'>
            <div className='service-image-container'>
              <Image imageInfo={{ image: blocks[0].image }} />
            </div>
            <HTMLContent content={blocks[0].content} />
          </div>
          <div className='column is-5'>
            <div className='service-image-container'>
              <Image imageInfo={{ image: blocks[1].image }} />
            </div>
            <HTMLContent content={blocks[1].content} />
          </div>
          <div className='column is-1' />
        </div>
      </div>
    </div>
  );
};

const ServiceOptionsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ServiceOptionsPageTemplate
        location={location}
        title={frontmatter.title}
        image={frontmatter.heroImageObject}
        blocks={frontmatter.blocks}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default ServiceOptionsPage;

export const pageQuery = graphql`
  query serviceOptionsPageQuery {
    markdownRemark(
      frontmatter: { templateKey: { eq: "service-options-page" } }
    ) {
      frontmatter {
        title
        heroImageObject {
          overlay
          image
        }
        blocks {
          image
          image_position
          content
          isCta
          buttonText
          linkObject {
            isExternal
            link
          }
        }
        content
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
